// export const BaseURL = 'http://192.168.100.119:8080';
// export const BaseURL = 'http://192.168.100.99:8073';
// export const BaseURL = 'http://127.0.0.1:8000';
// export const BaseURL = 'http://97.74.91.13:8080'; 
// export const BaseURL = 'https://luftek.luftekerp.com:8080'; 
export const BaseURL = 'https://api.luftekerp.com'; 

export function mapHeaders(data, headers){
    return data.map((item) => {
        const newItem = {};
        Object.keys(item).map((key) => {
            const newKey = headers[key];
            newItem[newKey] = item[key];
        });
        return newItem;
    });
}

export const getToken = () => {
    return localStorage.getItem("token");
};
